import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Alert" description="Accessible component to create alert messages for React" mdxType="SEO" />
    <h1 {...{
      "id": "alert"
    }}>{`Alert`}</h1>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/alert"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/alert`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#alert"
        }}>{`https://www.w3.org/TR/wai-aria-practices-1.2/#alert`}</a></li>
    </ul>
    <p>{`Screen-reader-friendly alert messages. In many apps developers add "alert" messages when network events or other things happen. Users with assistive technologies may not know about the message unless you develop for it.`}</p>
    <p>{`The Alert component will announce to assistive technologies whatever you render to the screen. If you don't have a screen reader on you won't notice a difference between rendering `}<inlineCode parentName="p">{`<Alert>`}</inlineCode>{` vs. a `}<inlineCode parentName="p">{`<div>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [messages, setMessages] = React.useState([]);
  return (
    <div>
      <button
        onClick={() => {
          setMessages((prevMessages) =>
            prevMessages.concat([\`Message #\${prevMessages.length + 1}\`])
          );
          setTimeout(() => {
            setMessages((prevMessages) => prevMessages.slice(1));
          }, 5000);
        }}
      >
        Add a message
      </button>
      <div>
        {messages.map((message, index) => (
          <Alert key={index}>{message}</Alert>
        ))}
      </div>
    </div>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/alert`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/alert`}</inlineCode>{`. Then import the component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/alert
# or
yarn add @reach/alert
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Alert from "@reach/alert";
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "alert-1"
    }}>{`Alert`}</h3>
    <h4 {...{
      "id": "alert-props"
    }}>{`Alert Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alert-div-props"
            }}><inlineCode parentName="a">{`div`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alert-type"
            }}><inlineCode parentName="a">{`type`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`enum`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#alert-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true\``}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "alert-div-props"
    }}>{`Alert div props`}</h5>
    <p>{`Any props not listed above will be spread onto the underlying `}<inlineCode parentName="p">{`div`}</inlineCode>{` element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Alert
      style={{
        background: "hsla(10, 50%, 50%, .10)",
        padding: "10px",
      }}
    >
      ❗️ Woah! Something went wrong.
    </Alert>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "alert-type"
    }}>{`Alert type`}</h5>
    <p><inlineCode parentName="p">{`type?: "assertive" | "polite"`}</inlineCode></p>
    <p>{`Controls whether the assistive technology should read immediately ("assertive") or wait until the user is idle ("polite").`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Alert type="polite">
  This is the default
</Alert>

<Alert type="assertive">
  If a screen reader is currently reading something and
  you render this, it will interrupt the user and read
  this immediately.
</Alert>
`}</code></pre>
    <p>{`You should probably stick with polite most of the time.`}</p>
    <h5 {...{
      "id": "alert-children"
    }}>{`Alert children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode;`}</inlineCode></p>
    <p>{`Regular React children.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Alert>
  <p>Whatever you want here</p>
  <p>Is fine.</p>
</Alert>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      